import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.css'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { LoginWithEmail, clearErr } from '../../store/authSlice';
import BackDrop from './components/BackDrop'
import { baseURL } from '../../config';
import { openNotification } from '../../components/helper'

const VerifyOTP = ({ setOtpVerified, email, setUniqueKey }) => {
    const [form] = Form.useForm()

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const onFinish = async (value) => {
        setLoading(true)
        let otp = value.otp.trim();
        const response = await fetch(`${baseURL}user/otp-validate`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'access': 'PAS', 'email': email.trim(), otp })
        }).then(res => res.json())
            .then(res => {
                if (res.api.responseCode === 2270) {
                    if (res.result.is_otp_valid === true) {
                        setUniqueKey(res.result.unique_valid_key, setOtpVerified(true))
                    }
                }
                else {
                    setError(res.message)
                }
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
    };

    const [fields, setFields] = useState()
    const [typing, setTyping] = useState(true)

    const [resendLoading, setResendLoading] = useState(false)
    const resendOTP = async () => {
        setResendLoading(true)
        const response = await fetch(`${baseURL}user/${email.trim()}/PAS/otp`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.json())
            .then(res => {
                if (res.api.responseCode === 2230) {
                    openNotification('OTP sent', true)
                }
                else {
                    setError(res.message)
                }
            })
            .catch(e => setError(e.message))
            .finally(() => setResendLoading(false, setDisabledTimer(180, setResendDisabled(true))))
    };

    useEffect(() => {
        setError('')
        if (form.isFieldsTouched()) {
            setTyping(true)
            const delayDebounceFn = setTimeout(() => {
                setTyping(false)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [fields])

    const [resendDisabled, setResendDisabled] = useState(true)
    const [disabledTimer, setDisabledTimer] = useState(180)

    useEffect(() => {
        if (disabledTimer === 0) {
            setResendDisabled(false)
        }

        if (!disabledTimer) return;

        const interval = setInterval(() => setDisabledTimer(disabledTimer - 1), 1000);

        return () => clearInterval(interval);
    }, [disabledTimer]);

    return (
        <Form
            name='form'
            form={form}
            onFieldsChange={setFields}
            onFinish={onFinish}
            style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <h4 className={styles.formLabel}>One time password (OTP)</h4>
            <Form.Item
                validateTrigger={['onChange']}
                help={''}
                name="otp"
                rules={[
                    {
                        required: true,
                        message: 'Required'
                    }
                ]}
            >
                <Input placeholder="Enter One time password" className={styles.formInput} type='number' />
            </Form.Item>
            <p className={styles.helpText} style={{ opacity: typing ? '0%' : '100%', marginBottom: (!typing && form.getFieldError('otp')[0]) ? '15px' : '0px' }}>{form.getFieldError('otp')[0]}</p>

            <h4 className={styles.warningMessage} style={{ opacity: error ? '100%' : '0%', padding: error ? '15px 30px' : '0px' }} >{error?.replaceAll('_', ' ')}</h4>

            <Button
                loading={loading}
                shape="round"
                style={{
                    height: '50px',
                    width: '100%'
                }}
                type="primary"
                htmlType="submit"
                className={styles.submitBtn}
                disabled={
                    !form.isFieldsTouched() ||
                    !form.getFieldValue('otp') ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                        .length > 0}
            >
                Verify OTP
            </Button>
            <h3
                className={styles.bottomText}
                style={{
                    width: '300px',
                    whiteSpace: 'initial'
                }}
            >A One Time Password (OTP) has been sent to {email}</h3>
            <Button type='text'
                disabled={resendDisabled}
                onClick={resendOTP}
                loading={resendLoading}
                style={{ marginTop: '15px', borderRadius: '25px' }}
                className={styles.bottomSubText}
            >Resend OTP {resendDisabled ? ('in ' + disabledTimer + ' seconds') : null}</Button>
        </Form>
    )
}

export default VerifyOTP;