import Main from "../../layout/Main";
import styles from './index.module.css'

import React, { useEffect, useState } from 'react';
import { DownOutlined, InboxOutlined, LogoutOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Layout, Button, Table, Select, Form, Input, Modal, Badge, Pagination } from 'antd';
import { useNavigate } from 'react-router-dom';
import { baseURL } from "../../config";
import userIcon from '../../icons/user-icon.png'
import { RequiredStar } from "../../components/RequiredStar";
import { useDispatch, useSelector } from "react-redux";
import { openNotification } from "../../components/helper";
import { getProviderUsers } from "../../store/providerSlice";
import { getPASUsers } from "../../store/authSlice";
const { Option } = Select;

const Users = () => {

    const [newUser, setNewUser] = useState(false)
    const [msg, setMsg] = useState('')
    const [selectedUser, setSelectedUser] = useState()

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)

    const handlePageChange = (p, s) => {
        setPage(p)
        setPageSize(s)
        dispatch(getPASUsers({ page: p - 1, pageSize: s }))
    }


    useEffect(() => { dispatch(getPASUsers({ page: page - 1, pageSize, })) }, [])

    const { users } = useSelector(state => state.auth)

    const [loading, setLoading] = useState(false)
    const activateUser = async (userID) => {
        setLoading(true)
        const response = await fetch(`${baseURL}user/${userID}/activate`, {
            method: 'PATCH',
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`
            },
        })
            .then(res => res.json())
            .then(res => {
                if (res.api.responseCode === 2030) {
                    openNotification('Activated User', 'Success')
                } else {
                    openNotification(res?.api?.response?.replaceAll('_', ' '), 'Error')
                }
            })
            .catch(e => openNotification(e.message))
            .finally(() => setLoading(false, dispatch(getPASUsers())))
    }
    const [isAddUserOpen, setIsAddUserOpen] = useState(false)
    const dispatch = useDispatch();


    const AddUser = () => {
        const [userForm] = Form.useForm();
        const [loading, setLoading] = useState(false)
        const addUser = vals => {
            setLoading(true)
            const data = {
                account: { 'access': 'PAS', 'role': vals.role },
                alternate_email: vals.alternate_email || null,
                primary_email: vals.primary_email,
                contact_number: vals.contact_number || null,
                first_name: vals.first_name,
                middle_name: vals.middle_name || null,
                last_name: vals.last_name,
            }
            const res = fetch(`${baseURL}user/profile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`,
                },
                body: JSON.stringify(data)
            })
                .then(res => res.json())
                .then(res => {
                    if (res?.api?.responseCode === 2010) openNotification('Added New User', 'Success')
                    else {
                        openNotification(res?.api?.response?.replaceAll('_', ' '), 'Error')
                    }
                })
                .catch(e => openNotification(e.message))
                .finally(() => setLoading(false, setIsAddUserOpen(false, dispatch(getPASUsers()))))
        }
        return (
            <Modal
                visible={isAddUserOpen}
                onCancel={() => setIsAddUserOpen(false)}
                cancelButtonProps={
                    {
                        danger: true,
                        style: { color: '#777', borderColor: '#ccc' },
                        className: styles.btnShadow,

                    }}
                okButtonProps={{
                    className: styles.btnShadow,
                    loading: loading
                }}
                okText='Add'
                onOk={() => userForm.submit()}

            >
                <Form autoComplete="off" onFinish={addUser} form={userForm} tabIndex='4' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center', }}>
                    <div className={styles.sectionCard} style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', }}>
                        <div className={styles.cardHeader}>
                            <h2 style={{ fontWeight: 'bold' }}>Add New User</h2>
                        </div>
                        <div className={styles.divider}> </div>
                        <div style={{ width: '32%', marginBottom: '-10px' }}>
                            <h4 className={styles.formLabel}>First Name <RequiredStar /> </h4>
                            <Form.Item
                                name="first_name"
                                rules={[{ required: true, message: 'Please Enter First Name' }]}
                            >
                                <Input placeholder="First Name" className={styles.formInput} />
                            </Form.Item>
                        </div>
                        <div style={{ width: '32%', marginBottom: '-10px' }}>
                            <h4 className={styles.formLabel}>Middle Name</h4>
                            <Form.Item
                                name="middle_name"
                            >
                                <Input placeholder="Middle Name" className={styles.formInput} />
                            </Form.Item>
                        </div>
                        <div style={{ width: '32%', marginBottom: '-10px' }}>
                            <h4 className={styles.formLabel}>Last Name<RequiredStar /></h4>
                            <Form.Item
                                name="last_name"
                                rules={[{ required: true, message: 'Please Enter Last Name' }]}
                            >
                                <Input placeholder="Last Name" className={styles.formInput} />
                            </Form.Item>
                        </div>
                        <h4 className={styles.formLabel}>Email Address<RequiredStar /></h4>
                        <Form.Item
                            name="primary_email"
                            style={{ width: '100%', marginBottom: '10px' }}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please Enter Email'
                                }
                                , {
                                    type: 'email',
                                    message: 'Please Enter Valid Email'
                                }
                            ]}
                        >
                            <Input placeholder="Email Address" className={styles.formInput} />
                        </Form.Item>
                        <h4 className={styles.formLabel}>Alternate Email Address</h4>
                        <Form.Item
                            name="alternate_email"
                            style={{ width: '100%', marginBottom: '10px' }}
                            rules={[{
                                type: 'email',
                                message: 'Please Enter Valid Email'
                            }
                            ]}
                        >
                            <Input placeholder="Alternate Email Address" className={styles.formInput} />
                        </Form.Item>
                        <h4 className={styles.formLabel}>Phone Number</h4>
                        <Form.Item
                            name="contact_number"
                            style={{ width: '100%', marginBottom: '10px' }}
                        >
                            <Input placeholder="Phone Number" type="number" className={styles.formInput} />
                        </Form.Item>

                        <h4 className={styles.formLabel}>Role <RequiredStar /></h4>
                        <Form.Item
                            name="role"
                            rules={[{ required: true, message: 'Please Select a Role' }]}
                            style={{ width: '100%', marginBottom: '10px' }}
                        >
                            <Select
                                getPopupContainer={(trigger) => trigger.parentElement}
                                dropdownStyle={{ borderRadius: '25px' }}
                                placeholder='Select a Role'
                            >
                                <Option value="Super_Admin">
                                    Super Admin
                                </Option>
                                <Option value="Admin">
                                    Admin
                                </Option>
                                <Option value="Operator">
                                    Operator
                                </Option>

                            </Select>
                        </Form.Item>
                    </div>
                </Form>

            </Modal >
        )
    }

    const [isDeleteUserOpen, setIsDeleteUserOpen] = useState(false)

    const DeleteUser = ({ user, isModalOpen, setIsModalOpen }) => {
        const [loading, setLoading] = useState(false)
        const deleteUser = async () => {
            setLoading(true)
            await fetch(`${baseURL}user/${user.user_id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('x-auth-token')}`,
                }
            })
                .then(res => res.json())
                .then(res => {
                    if (res?.api?.responseCode === 2050) openNotification(`Deleted User ${user?.first_name} ${user?.last_name}`, 'Success')
                    else {
                        openNotification(res?.api?.response?.replaceAll('_', ' '), 'Error')
                    }
                })
                .finally(() => setLoading(false, setIsModalOpen(false, dispatch(getPASUsers()))))

        }
        return (
            <Modal
                visible={isModalOpen}
                onCancel={() => setIsDeleteUserOpen(false)}
                cancelButtonProps={{
                    danger: true,
                    style: { color: '#777', borderColor: '#ccc' },
                    className: styles.btnShadow,

                }}
                okButtonProps={{
                    className: styles.btnShadow,
                    type: 'danger',
                    loading: loading
                }}
                okText={'Yes, Delete.'}
                onOk={() => deleteUser()}
            >
                <h4 style={{
                    fontSize: '16px',
                    lineHeight: '1.143em',
                    color: '#303350',
                    fontWeight: '700',
                    margin: '10px',
                }}>
                    Are you sure you want to delete user <span style={{ color: 'red' }}>{`${user?.first_name} ${user?.last_name} ?`}</span>
                </h4>
            </Modal >
        )
    }

    const columns = [
        {
            title: 'Name',
            dataIndex: 'full_name',
            width: 60,
        },
        {
            title: 'Email',
            dataIndex: 'primary_email',
            width: 90,
            ellipsis: true,

        },
        {
            title: 'Phone',
            dataIndex: 'contact_number',
            width: 70,
            render: contact => contact || 'N/A'
        },
        {
            title: 'Role',
            dataIndex: 'account',
            width: 50,
            render: account => account?.role?.replaceAll('_', ' ') || 'N/A'
        },
        {
            title: 'Status',
            dataIndex: 'account',
            width: 40,
            render: account => account.meta.status === 'Active' ?
                <div className={styles.statusContainer}>
                    <div className={styles.statusDot}></div>
                    <p> Active</p>
                </div> :
                <div className={styles.statusContainer}>
                    <div className={styles.statusDot}></div>
                    <p> Inactive</p>
                </div>
        },
        {
            title: 'Action',
            dataIndex: '',
            width: 40,
            render: user => <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <Button disabled={user.account.meta.status === 'Active'} type="primary" size="small" style={{ borderRadius: '15px' }} onClick={() => activateUser(user.user_id)}>Activate</Button>
            </div>
        },

    ];

    return (
        <Main pageName="Providers">
            <div className={styles.container}>

                <div className={styles.tableContainer} style={{ marginTop: '25px', backgroundColor: 'white', margin: '30px 10px' }}>
                    <AddUser isModalOpen={isAddUserOpen} />
                    <DeleteUser isModalOpen={isDeleteUserOpen} user={selectedUser} setIsModalOpen={setIsDeleteUserOpen} />
                    <div
                        style={{ display: 'flex', justifyContent: 'space-between', margin: '25px', backgroundColor: 'white' }}
                    >
                        <div style={{ backgroundColor: 'white' }}>
                            <h2 style={{ fontWeight: 'bold' }}>User Access Settings</h2>
                            <h4 style={{ color: '#888' }}>These Users are the ones who will be accessing the Onboarding Portal.</h4>
                        </div>
                        <Button
                            className={styles.btnShadow}
                            onClick={() => setIsAddUserOpen(true)}
                        >Add New User</Button>
                    </div>
                    <Table
                        pagination={false}
                        key='user_id'
                        dataSource={users.data}
                        columns={columns}
                    />
                    <Pagination
                        style={{ margin: '20px' }}
                        current={page}
                        onChange={handlePageChange}
                        size="small"
                        total={users.property?.total_elements || 0}
                        showSizeChanger
                        defaultPageSize={10}
                        pageSize={pageSize}
                        // onShowSizeChange={handlePageSizeChange}
                        pageSizeOptions={[10, 20, 30, 40, 50]}

                    />
                </div>

            </div>
        </Main>
    )
}

export default Users;