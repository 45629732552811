import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.css'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { LoginWithEmail, clearErr } from '../../store/authSlice';
import BackDrop from './components/BackDrop'
import { baseURL } from '../../config';


const SetNewPassword = ({ uniqueKey, email }) => {
    const navigate = useNavigate()
    const [pwdShow, setPwdShow] = useState(false)
    const [pwdShow2, setPwdShow2] = useState(false)
    const [form] = Form.useForm()

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const onFinish = async (value) => {
        setLoading(true)
        let password = value.password.trim();

        const response = await fetch(`${baseURL}user/set-password`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ 'access': 'PAS', 'email': email.trim(), password, 'unique_valid_key': uniqueKey })
        })
            .then(async res => {
                const resJson = await res.json()

                if (resJson.api.responseCode === 2250) {
                    localStorage.setItem('x-auth-token', res.headers.get('token'))
                    localStorage.setItem('user', JSON.stringify(resJson.result));
                    navigate('/')

                } else {
                    setError(resJson.api.response.replaceAll('_', ' '))
                }
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
    };

    const [fields, setFields] = useState()
    const [typing, setTyping] = useState(true)

    useEffect(() => {
        setError('')
        if (form.isFieldsTouched()) {
            setTyping(true)
            const delayDebounceFn = setTimeout(() => {
                setTyping(false)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [fields])

    return (
        <Form
            name='form'
            form={form}
            onFieldsChange={setFields}
            onFinish={onFinish}
        >
            <h4 className={styles.formLabel}>Set Password</h4>
            <div style={{ position: 'relative' }}>
                <Form.Item
                    name="password"
                    validateTrigger={['onChange']}
                    help={''}

                    rules={[
                        {
                            required: true,
                            message: 'Password is required',
                        },
                        {
                            min: 6,
                            message: 'Password should be minimum 6 characters',
                        }, {
                            max: 64,
                            message: 'Password cannot exceed 64 characters',
                        }
                    ]}

                >
                    <Input placeholder="Enter password of your choice" className={styles.formInput} type={pwdShow ? 'text' : 'password'} />
                </Form.Item>
                <Button onClick={() => setPwdShow(!pwdShow)} type='text'
                    style={{
                        borderRadius: '25px',
                        position: 'absolute',
                        right: '10px',
                        top: '-20px',
                        bottom: '0',
                        margin: 'auto',
                    }}
                >
                    {pwdShow ?
                        <EyeOutlined /> :
                        <EyeInvisibleOutlined />}</Button>
            </div>
            <p className={styles.helpText} style={{ opacity: typing ? '0%' : '100%', marginBottom: (!typing && form.getFieldError('password')[0]) ? '15px' : '0px' }}>{form.getFieldError('password')[0]}</p>

            <h4 className={styles.formLabel}>Confirm Password</h4>
            <div style={{ position: 'relative' }}>
                <Form.Item
                    name="confirm_password"
                    validateTrigger={['onChange']}
                    help={''}
                    dependencies={['password']}

                    rules={[
                        {
                            required: true,
                            message: 'required',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Passwords do not match'));
                            },
                        }),
                    ]}

                >
                    <Input placeholder="Confirm password" className={styles.formInput} type={pwdShow2 ? 'text' : 'password'} />
                </Form.Item>
                <Button onClick={() => setPwdShow2(!pwdShow2)} type='text'
                    style={{
                        borderRadius: '25px',
                        position: 'absolute',
                        right: '10px',
                        top: '-20px',
                        bottom: '0',
                        margin: 'auto',
                    }}
                >
                    {pwdShow2 ?
                        <EyeOutlined /> :
                        <EyeInvisibleOutlined />}</Button>
            </div>
            <p className={styles.helpText} style={{ opacity: typing ? '0%' : '100%', marginBottom: (!typing && form.getFieldError('confirm_password')[0]) ? '15px' : '0px' }}>{form.getFieldError('confirm_password')[0]}</p>


            <h4 className={styles.warningMessage} style={{ opacity: error ? '100%' : '0%', padding: error ? '15px 30px' : '0px' }} >{error?.replaceAll('_', ' ')}</h4>

            <Button
                loading={loading}
                shape="round"
                style={{
                    height: '50px',
                    width: '100%'
                }}
                type="primary"
                htmlType="submit"
                className={styles.submitBtn}
                disabled={
                    !form.isFieldsTouched() ||
                    !form.getFieldValue('password') ||
                    !form.getFieldValue('confirm_password') ||
                    form.getFieldsError().filter(({ errors }) => errors.length)
                        .length > 0}
            >
                Submit
            </Button>

        </Form>
    )
}

export default SetNewPassword;