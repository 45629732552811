import { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoaderComponenet from '../components/Loader';
// import Policy from '../pages/Employers/Policy';
import RequestOTP from '../pages/Login/RequestOTP';
import Requests from '../pages/Requests';
import Users from '../pages/Users';
import { PrivateRoute } from './PrivateRoute';

const Providers = lazy(() => import('../pages/Providers'));
const OnboardProvider = lazy(() => import('../pages/Providers/Onboard'));
const EmailStatus = lazy(() => import('../pages/Providers/EmailStatus'))
const ProviderProfile = lazy(() => import('../pages/Providers/Profile'))


const Wellness = lazy(() => import('../pages/Wellness'));
const OnboardWellness = lazy(() => import('../pages/Wellness/Onboard'));
const WellnessProfile = lazy(() => import('../pages/Wellness/Profile'))
// const Wellness = lazy(() => import('../pages/Wellness'));
// const OnboardWellness = lazy(() => import('../pages/Wellness/Onboard'));
// const AddWellnessLocation = lazy(() => import('../pages/Wellness/AddLocation'));
// const WellnessDetail = lazy(() => import('../pages/Wellness/WellnessDetail'));

const Employers = lazy(() => import('../pages/Employers'));
const OnboardEmployer = lazy(() => import('../pages/Employers/Onboard'));
const EmployerProfile = lazy(() => import('../pages/Employers/Profile'))


const UpdateRequests = lazy(() => import('../pages/UpdateRequests'))

const Dashboard = lazy(() => import('../pages/Dashboard'));

const Login = lazy(() => import('../pages/Login'));
const Welcome = lazy(() => import('../pages/Welcome'));
const RequestToken = lazy(() => import('../pages/ResetPassword/RequestToken'));
const ResetPassword = lazy(() => import('../pages/ResetPassword/ResetPassword'));
const NotFound = lazy(() => import('../pages/404'));
const Reports = lazy(() => import('../pages/Reports'))

const RouteSetup = () => {
  return (
    <Suspense fallback={<LoaderComponenet />}>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Dashboard />
            </PrivateRoute>
          }
        />
        <Route
          path="/update-requests"
          element={
            <PrivateRoute>
              <UpdateRequests />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers"
          element={
            <PrivateRoute>
              <Providers />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/onboard"
          element={
            <PrivateRoute>
              <OnboardProvider />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/:providerId"
          element={
            <PrivateRoute>
              <ProviderProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/:parentId/:providerId"
          element={
            <PrivateRoute>
              <ProviderProfile branch />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/email-status"
          element={
            <PrivateRoute>
              <EmailStatus />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/:providerId/add-branch"
          element={
            <PrivateRoute>
              <OnboardProvider branch />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/:providerId/upload-docs"
          element={
            <PrivateRoute>
              <OnboardProvider docs />
            </PrivateRoute>
          }
        />
        <Route
          path="/providers/:parentId/:providerId/upload-docs"
          element={
            <PrivateRoute>
              <OnboardProvider docs branch />
            </PrivateRoute>
          }
        />



        <Route
          path="/employers"
          element={
            <PrivateRoute>
              <Employers />
            </PrivateRoute>
          }
        />
        <Route
          path="/employers/onboard"
          element={
            <PrivateRoute>
              <OnboardEmployer />
            </PrivateRoute>
          }
        />
        <Route
          path="/employers/:employerId"
          element={
            <PrivateRoute>
              <EmployerProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/employers/:employerId/upload-docs"
          element={
            <PrivateRoute>
              <OnboardEmployer docs />
            </PrivateRoute>
          }
        />
        {/* <Route
          path="/employers/:employerId/policy"
          element={
            <PrivateRoute>
              <Policy />
            </PrivateRoute>
          }
        /> */}


        <Route
          path="/wellness-partners"
          element={
            <PrivateRoute>
              <Wellness />
            </PrivateRoute>
          }
        />
        <Route
          path="/wellness-partners/onboard"
          element={
            <PrivateRoute>
              <OnboardWellness />
            </PrivateRoute>
          }
        />
        <Route
          path="/wellness-partners/:wellnessId"
          element={
            <PrivateRoute>
              <WellnessProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/wellness-partners/:parentId/:wellnessId"
          element={
            <PrivateRoute>
              <WellnessProfile branch />
            </PrivateRoute>
          }
        />
        <Route
          path="/wellness-partners/:wellnessId/add-location"
          element={
            <PrivateRoute>
              <OnboardWellness branch />
            </PrivateRoute>
          }
        />
        <Route
          path="/wellness-partners/:wellnessId/upload-docs"
          element={
            <PrivateRoute>
              <OnboardWellness docs />
            </PrivateRoute>
          }
        />
        <Route
          path="/wellness-partners/:parentId/:wellnessId/upload-docs"
          element={
            <PrivateRoute>
              <OnboardWellness docs branch />
            </PrivateRoute>
          }
        />

        <Route
          path="/reports"
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />

        <Route
          path="/requests"
          element={
            <PrivateRoute>
              <Requests />
            </PrivateRoute>
          }
        />

        <Route
          path="/users"
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />

        <Route path="/login" element={<Login />} />
        <Route path="/welcome/" element={<Welcome />} />
        <Route path="/verify-email" element={<RequestOTP />} />
        <Route path="/reset-password" element={<RequestOTP passwordReset />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RouteSetup;
