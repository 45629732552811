import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import styles from './index.module.css'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { LoginWithEmail, clearErr } from '../../store/authSlice';
import BackDrop from './components/BackDrop'
import { baseURL } from '../../config';
import SetNewPassword from './SetNewPassword';
import VerifyOTP from './VerifyOTP';

const RequestOTP = ({ passwordReset }) => {
    const [form] = Form.useForm()

    const [error, setError] = useState('')
    const [loading, setLoading] = useState(false)

    const onFinish = async (value) => {
        setLoading(true)
        let email = value.email.trim();

        const response = await fetch(`${baseURL}user/${email}/PAS/otp`, {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(res => res.json())
            .then(res => {
                if (res.api.responseCode === 2230) {
                    setOtpSent(true, setError('', setFields()))
                }
                else {
                    setError(res.message)
                }
            })
            .catch(e => setError(e.message))
            .finally(() => setLoading(false))
    };

    const [otpSent, setOtpSent] = useState(false)
    const [otpVerified, setOtpVerified] = useState(false)
    const [uniqueKey, setUniqueKey] = useState('')



    const [fields, setFields] = useState()
    const [typing, setTyping] = useState(true)

    useEffect(() => {
        setError('')
        if (form.isFieldsTouched()) {
            setTyping(true)
            const delayDebounceFn = setTimeout(() => {
                setTyping(false)
            }, 1000)
            return () => clearTimeout(delayDebounceFn)
        }
    }, [fields])

    const requestOTPForm = <Form
        name='form'
        form={form}
        onFieldsChange={setFields}
        onFinish={onFinish}
    >
        <h4 className={styles.formLabel}>Email</h4>
        <Form.Item
            validateTrigger={['onChange']}
            help={''}
            name="email"
            rules={[
                {
                    required: true,
                    message: 'Email is required'
                }, {
                    type: 'email',
                    message: 'Email appears to be invalid'
                },
                {
                    min: 7,
                    message: 'Email should be minimum 7 characters'
                }
            ]}
        >
            <Input placeholder="Email" className={styles.formInput} />
        </Form.Item>
        <p className={styles.helpText} style={{ opacity: typing ? '0%' : '100%', marginBottom: (!typing && form.getFieldError('email')[0]) ? '15px' : '0px' }}>{form.getFieldError('email')[0]}</p>

        {passwordReset || otpSent ? null : <div style={{ textAlign: 'right', width: '100%', justifySelf: 'flex-end', alignSelf: 'flex-end', zIndex: '100' }}>
            <a className={styles.link} href="/reset-password" >Forgot your password?</a>
        </div>}


        <h4 className={styles.warningMessage} style={{ opacity: error ? '100%' : '0%', padding: error ? '15px 30px' : '0px' }} >{error?.replaceAll('_', ' ')}</h4>

        <Button
            loading={loading}
            shape="round"
            style={{
                height: '50px',
                width: '100%'
            }}
            type="primary"
            htmlType="submit"
            className={styles.submitBtn}
            disabled={
                !form.isFieldsTouched() ||
                !form.getFieldValue('email') ||
                form.getFieldsError().filter(({ errors }) => errors.length)
                    .length > 0}
        >
            {passwordReset ? 'Reset Password' : 'Verify Email'}
        </Button>

    </Form>

    return (
        <div
            className={styles.container}
        >
            <BackDrop passwordReset={passwordReset} />

            <div
                className={styles.sidePanelContainer}
            >
                <h2 className={styles.loginHeader}>{passwordReset ? 'Reset Password' : 'Verify your email'}</h2>
                <h3 className={styles.loginSubHeader}>
                    {
                        otpSent ? (
                            otpVerified ?
                                'Kindly enter your new password' :
                                'Kindly enter OTP received on your registered Email'
                        ) : 'Kindly enter your email address below to enable us verify your identity and set your password'
                    }
                </h3>
                {otpSent ?
                    (
                        otpVerified ?
                            <SetNewPassword uniqueKey={uniqueKey} email={form.getFieldValue('email')} />
                            :
                            <VerifyOTP setOtpVerified={setOtpVerified} email={form.getFieldValue('email')} setUniqueKey={setUniqueKey} />
                    )
                    :
                    requestOTPForm

                }
                {passwordReset || otpSent ? null : <h3 className={styles.newUserText} style={{ marginTop: '' }}>Know your password? <a href='/login' style={{ fontSize: '14px', fontWeight: '500', color: '#3AB44D' }}>Log In to account</a></h3>}
            </div>
        </div>
    )
}

export default RequestOTP;