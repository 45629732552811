import { useState } from "react"
import styles from './index.module.css'
import edenLogoV from '../../icons/logoV.png'
import { CloseOutlined, MenuOutlined, ScanOutlined } from "@ant-design/icons"

import dashboardIcon from '../../icons/dashboard.png'
import providerIcon from '../../icons/provider.png'
import employerIcon from '../../icons/employers.png'
import wellnessIcon from '../../icons/wellness-partner.png'
import reportIcon from '../../icons/report.png'
import { useDispatch, useSelector } from "react-redux"
// import { toggleSideBar } from "../../store/authSlice"
import { NavLink } from "react-router-dom"
export const SideBar = () => {

    // const { isSidebarOpen } = useSelector(state => state.auth)
    const [isSidebarOpen, setSidebarOpen] = useState(false)
    // const dispatch = useDispatch();

    const links = [
        {
            id: 0,
            link: '/',
            title: <h3>Dashboard</h3>,
            padding: '2px',
            icon: dashboardIcon
        },
        {
            id: 1,
            link: '/providers',
            title: <h3>Providers</h3>,
            padding: '3px',
            icon: providerIcon,
        },
        {
            id: 2,
            link: '/employers',
            title: <h3>Employers</h3>,
            padding: '4px',
            icon: employerIcon
        },
        {
            id: 3,
            link: '/wellness-partners',
            title: <h3>Wellness<br /> Partners</h3>,
            padding: '2px',
            icon: wellnessIcon
        },
        {
            id: 4,
            link: '/reports',
            title: <h3>Reports</h3>,
            padding: '5px',
            icon: reportIcon
        },
    ]

    return (
        <div
            className={styles.container}
            style={{
                width: isSidebarOpen ? '275px' : '110px'
            }}
        >
            <div
                className={isSidebarOpen ? styles.menuBtn : styles.menuBtnHidden}
                onClick={() => setSidebarOpen(!isSidebarOpen)}
            >
                <CloseOutlined />
            </div>

            <img
                src={edenLogoV}
                className={styles.logo}
                style={{ width: isSidebarOpen ? '85px' : '75px' }}
                onClick={() => setSidebarOpen(!isSidebarOpen)}
            />

            {links.map((item) => (
                <NavLink
                    key={item.id}
                    className={({ isActive }) => (isActive ? styles.linkItemActive : styles.linkItem)}
                    to={item.link}
                >
                    <div className={styles.linkContainer}>
                        <img src={item.icon} className={styles.navIcon} style={{ padding: item.padding }} />
                        {isSidebarOpen ? item.title : null}
                    </div>
                </NavLink>
            ))}

        </div >
    )
}