
import styles from './index.module.css'

const BackDrop = () => <div
    style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        backgroundColor: '#3AB44D',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }}
>
    <h3 className={styles.backdropText}>Health Insurance Made Easy</h3>
    <div style={{ position: 'absolute', bottom: '0', width: '100%', backgroundColor: '#3AB44D', height: '100%', zIndex: '99', mixBlendMode: 'multiply' }}></div>
    <img src='/login_backdrop.png' style={{ position: 'absolute', bottom: '0', width: '100%' }} />
</div>

export default BackDrop;