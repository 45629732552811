import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { baseURL } from '../config'
const initialState = {
    loading: false,
    data: [],
    isLoading: false,
    details: {
        wellness: null,
        isLoading: false
    },
    property: {
        empty: false,
        first: false,
        last: false,
        page_number: 0,
        page_size: 0,
        sort: {
            empty: false,
            sorted: false,
            unsorted: false
        },
        total_elements: 0,
        total_pages: 0
    }
};

let token = localStorage.getItem('x-auth-token');
export const loadWellness = createAsyncThunk('wellness/loadWellness', async ({ page, pageSize }) => {
    return await fetch(`${baseURL}pas/wellness-partner/profiles?page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => {
            if (res.api.responseCode === 4150) {
                localStorage.clear();
                window.location.reload();
            }
            return (res.result)
        })
        .catch(e => console.log(e))
});
export const searchWellness = createAsyncThunk('wellness/searchWellness', async ({ query, page, pageSize }) => {
    return await fetch(`${baseURL}pas/wellness-partner/search?query=${query}&page=${page}&size=${pageSize}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});
export const getWellnessDetails = createAsyncThunk('wellness/getWellnessDetails', async (wellnessId) => {
    return await fetch(`${baseURL}pas/wellness-partner/${wellnessId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(async res => {
            const branches = await fetch(`${baseURL}pas/wellness-partner/${wellnessId}/location/list`, {
                headers: {
                    "content-type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
                }
            })
                .then(branches => branches.json())
                .then(branches => branches.result)
            return ({ ...res.result, branch: branches })
        })
        .catch(e => console.log(e))
});
export const addLocation = createAsyncThunk('wellness/addLocation', async ({ wellnessId, data }) => {
    return await fetch(`${baseURL}pas/wellness-partner/${wellnessId}`, {
        headers: {
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        }
    })
        .then(res => res.json())
        .then(res => res.result)
        .catch(e => console.log(e))
});

export const onboardWellness = createAsyncThunk('wellness/onboardWellness', async (data) => {
    return fetch(`${baseURL}pas/wellness-partner/onboard`, {
        method: 'POST',
        headers: {
            "accept": "*/*",
            "content-type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem('x-auth-token')
        },
        body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(e => console.log(e))
});

export const uploadDocs = createAsyncThunk('wellness/uploadDocs', async ({ wellnessId, logo, tin, contract, misc, services }) => {
    const data = new FormData();
    data.append('logo', logo[0])
    data.append('tin', tin[0])
    contract.map(item => data.append('contract', item))
    misc.map(item => data.append('misc', item))
    services.map(item => data.append('services', item))

    return fetch(`${baseURL}pas/wellness-partner/${wellnessId}/upload-documents`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('x-auth-token'),
        },
        body: data
    })
        .then(res => res.json())
        .catch(e => console.log(e))
});

export const WellnessSlice = createSlice({
    name: 'wellness',
    initialState: initialState,
    reducers: {

    },
    extraReducers(builder) {
        builder.addCase(loadWellness.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(loadWellness.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(loadWellness.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(searchWellness.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(searchWellness.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload?.content;
            state.property = action.payload?.property;
        });
        builder.addCase(searchWellness.rejected, (state) => {
            state.loading = false;
        });
        builder.addCase(getWellnessDetails.pending, (state) => {
            state.details.isLoading = true;
        });
        builder.addCase(getWellnessDetails.fulfilled, (state, action) => {
            state.details.isLoading = false;
            state.details.wellness = action.payload;
        });
        builder.addCase(getWellnessDetails.rejected, (state) => {
            state.details.isLoading = false;
        });
    },
});

export default WellnessSlice.reducer;
