import Header from './Header/Header';
import styles from './index.module.css';
import { SideBar } from './SideBar/SideBar';
function Main({ children, pageName }) {
  return (
    <div className={styles.mainContainer} >
      <SideBar />
      <div className={styles.contain}  >
        <Header pageName={pageName} />
        <div >{children}</div>
      </div>
    </div>
  );
}

export default Main;
